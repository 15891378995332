export const changeSeoValues = (configData) => {
  // FavIcon
  if (configData?.imageUrls?.favIcon) {
    document.getElementById('favicon')?.setAttribute('href', configData.imageUrls.favIcon)
  }
  // Title
  if(configData?.texts?.seoTitle){
    document.title = configData.texts.seoTitle
  }
  // Description
  document.querySelector('meta[name="description"]')?.setAttribute(
    'content',
    configData?.texts?.seoDescription
      ? configData.texts.seoDescription
      : 'Learning Management System'
  )
}

export const addFontFaces = (configData) => {
  configData?.fontFaces?.forEach(fontFace => {
    let newStyle = document.createElement('style');
    newStyle.appendChild(document.createTextNode(fontFace));
    document.head.appendChild(newStyle);
  });
}

export const addBackgrounds = (root, configData) => {
  root?.style.setProperty("--landing-bg-desktop","url('"+configData?.imageUrls?.landingBgDesktop+"')")
  root?.style.setProperty("--landing-bg-tablet","url('"+configData?.imageUrls?.landingBgTablet+"')")
  root?.style.setProperty("--landing-bg-mobile","url('"+configData?.imageUrls?.landingBgMobile+"')")

  root?.style.setProperty("--login-bg-desktop","url('"+configData?.imageUrls?.loginBgDesktop+"')")
  root?.style.setProperty("--login-bg-tablet","url('"+configData?.imageUrls?.loginBgTablet+"')")
  root?.style.setProperty("--login-bg-mobile","url('"+configData?.imageUrls?.loginBgMobile+"')")
}

const colorsArray = [
  {cssColor: '--primary-background-color', configColor: 'primaryBackgroundColor', defaultColor: '#FBFCFD'},
  {cssColor: '--secondary-background-color', configColor: 'secondaryBackgroundColor', defaultColor: '#FBFCFD'},
  {cssColor: '--welcome-title-color', configColor: 'welcomeTitleColor', defaultColor: '#023770'},
  {cssColor: '--welcome-subtitle-color', configColor: 'welcomeSubtitleColor', defaultColor: '#6787A9'},
  {cssColor: '--login-form-bg-color', configColor: 'loginFormBgColor', defaultColor: '#FFFFFF'},
  {cssColor: '--login-form-input-bg-color', configColor: 'loginFormInputBgColor', defaultColor: '#FBFCFD'},
  {cssColor: '--login-form-input-text-color', configColor: 'loginFormInputTextColor', defaultColor: '#6787A9'},
  {cssColor: '--login-form-input-border-color', configColor: 'loginFormInputBorderColor', defaultColor: '#E5EBF0'},
  {cssColor: '--login-form-btn-bg-color', configColor: 'loginFormBtnBgColor', defaultColor: '#023770'},
  {cssColor: '--login-form-btn-text-color', configColor: 'loginFormBtnTextColor', defaultColor: '#FBFCFD'},
  {cssColor: '--login-form-links-color', configColor: 'loginFormLinksColor', defaultColor: '#6787A9'},
  {cssColor: '--input-invalid-border-color', configColor: 'inputInvalidBorderColor', defaultColor: '#F39200'},
  {cssColor: '--form-error-color', configColor: 'formErrorColor', defaultColor: '#F39200'},
  {cssColor: '--form-box-shadow-color', configColor: 'formBoxShadowColor', defaultColor: '#0237701D'},
  {cssColor: '--header-menu-item-color', configColor: 'headerMenuItemColor', defaultColor: '#6787A9'},
  {cssColor: '--header-bg-color', configColor: 'headerBgColor', defaultColor: '#FBFCFD'},
  {cssColor: '--header-selected-menu-item-color', configColor: 'headerSelectedMenuItemColor', defaultColor: '#023770'},
  {cssColor: '--selected-menu-item-border-color', configColor: 'selectedMenuItemBorderColor', defaultColor: '#F39200'},
  {cssColor: '--header-dropdown-bg-color', configColor: 'headerDropdownBgColor', defaultColor: '#6787A9'},
  {cssColor: '--header-dropdown-selected-bg-color', configColor: 'headerDropdownSelectedBgColor', defaultColor: '#E5EBF0'},
  {cssColor: '--header-dropdown-item-text-color', configColor: 'headerDropdownItemTextColor', defaultColor: '#FBFCFD'},
  {cssColor: '--header-dropdown-item-subtext-color', configColor: 'headerDropdownItemSubtextColor', defaultColor: '#C0C0C0'},
  {cssColor: '--header-search-input-text-color', configColor: 'headerSearchInputTextColor', defaultColor: '#FFFFFF'},
  {cssColor: '--footer-background-color', configColor: 'footerBackgroundColor', defaultColor: '#E5EBF0'},
  {cssColor: '--footer-color', configColor: 'footerColor', defaultColor: '#6787A9'},
  {cssColor: '--scrollTop-background-color', configColor: 'scrollTopBackgroundColor', defaultColor: '#6787A9'},
  {cssColor: '--scrollTop-color', configColor: 'scrollTopColor', defaultColor: '#FFFFFF'},
  {cssColor: '--course-content-bg-color', configColor: 'courseContentBgColor', defaultColor: '#FBFCFD'},
  {cssColor: '--all-courses-navbar-bg-color', configColor: 'allCoursesNavbarBgColor', defaultColor: '#023770'},
  {cssColor: '--all-courses-navbar-title-color', configColor: 'allCoursesNavbarTitleColor', defaultColor: '#FBFCFD'},
  {cssColor: '--all-courses-navbar-play-icon-color', configColor: 'allCoursesNavbarPlayIconColor', defaultColor: '#FBFCFD'},
  {cssColor: '--all-courses-grid-item-background-color', configColor: 'allCoursesGridItemBackgroundColor', defaultColor: '#FFFFFF'},
  {cssColor: '--all-courses-item-time-length-color', configColor: 'allCoursesItemTimeLengthColor', defaultColor: '#6787A9'},
  {cssColor: '--all-courses-item-subtitle-color', configColor: 'allCoursesItemSubtitleColor', defaultColor: '#6787A9'},
  {cssColor: '--all-courses-item-title-color', configColor: 'allCoursesItemTitleColor', defaultColor: '#023770'},
  {cssColor: '--all-courses-item-button-background-color', configColor: 'allCoursesItemButtonBackgroundColor', defaultColor: '#E5EBF0'},
  {cssColor: '--all-courses-item-button-primary-background-color', configColor: 'allCoursesItemButtonPrimaryBackgroundColor', defaultColor: '#023770'},
  {cssColor: '--all-courses-item-button-color', configColor: 'allCoursesItemButtonColor', defaultColor: '#6787A9'},
  {cssColor: '--all-courses-item-button-primary-color', configColor: 'allCoursesItemButtonPrimaryColor', defaultColor: '#FBFCFD'},
  {cssColor: '--myCourses-navbar-bg-color', configColor: 'myCoursesNavbarBgColor', defaultColor: '#023770'},
  {cssColor: '--myCourses-navbar-text-color', configColor: 'myCoursesNavbarTextColor', defaultColor: '#FBFCFD'},
  {cssColor: '--myCourses-navbar-selected-text-color', configColor: 'myCoursesNavbarSelectedTextColor', defaultColor: '#FBFCFD'},
  {cssColor: '--selected-navbar-item-border-color', configColor: 'selectedNavbarItemBorderColor', defaultColor: '#F4B249'},
  {cssColor: '--myCourse-subjects-background-color', configColor: 'myCourseSubjectsBackgroundColor', defaultColor: '#6787A9'},
  {cssColor: '--myCourse-subjects-items-background-color', configColor: 'myCourseSubjectsItemsBackgroundColor', defaultColor: '#526C87'},
  {cssColor: '--myCourse-subjects-items-active-bg-color', configColor: 'myCourseSubjectsActiveItemBgColor', defaultColor: '#34475C'},
  {cssColor: '--myCourse-subjects-lang-toggle-background-color', configColor: 'myCourseSubjectsLangToggleBackgroundColor', defaultColor: '#F39200'},
  {cssColor: '--myCourse-subjects-lang-toggle-text-color', configColor: 'myCourseSubjectsLangToggleTextColor', defaultColor: '#FBFCFD'},
  {cssColor: '--myCourse-subjects-color', configColor: 'myCourseSubjectsColor', defaultColor: '#FBFCFD'},
  {cssColor: '--myCourse-subjects-items-color', configColor: 'myCourseSubjectsItemsColor', defaultColor: '#FBFCFD'},
  {cssColor: '--myCourse-subjects-finished-tick-color', configColor: 'myCourseSubjectsFinishedTickColor', defaultColor: '#FBFCFD'},
  {cssColor: '--myCourse-subjects-scrollbar-color', configColor: 'myCourseSubjectsScrollbarColor', defaultColor: '#F39200'},
  {cssColor: '--myCourse-quizResult-bg-color', configColor: 'myCourseQuizResultBgColor', defaultColor: '#E5EBF0'},
  {cssColor: '--myCourse-quizResult-title-color', configColor: 'myCourseQuizResultTitleColor', defaultColor: '#023770'},
  {cssColor: '--myCourse-quizResult-text-primary-color', configColor: 'myCourseQuizResultTextPrimaryColor', defaultColor: '#3E436F'},
  {cssColor: '--myCourse-quizResult-success-color', configColor: 'myCourseQuizResultSuccessColor', defaultColor: '#03BB86'},
  {cssColor: '--myCourse-quizResult-failure-color', configColor: 'myCourseQuizResultFailureColor', defaultColor: '#DE3A5A'},
  {cssColor: '--myCourse-quizResult-text-secondary-color', configColor: 'myCourseQuizResultTextSecondaryColor', defaultColor: '#6787A9'},
  {cssColor: '--myCourse-quizResult-btn-bg-color', configColor: 'myCourseQuizResultBtnBgColor', defaultColor: '#023770'},
  {cssColor: '--myCourse-quizResult-btn-text-color', configColor: 'myCourseQuizResultBtnTextColor', defaultColor: '#FBFCFD'},
  {cssColor: '--myCourse-details-title-color', configColor: 'myCourseDetailsTitleColor', defaultColor: '#023770'},
  {cssColor: '--myCourse-details-text-color', configColor: 'myCourseDetailsTextColor', defaultColor: '#023770'},
  {cssColor: '--myCourse-play-btn-bg-color', configColor: 'myCoursePlayBtnBgColor', defaultColor: '#F39200'},
  {cssColor: '--myCourse-play-btn-text-color', configColor: 'myCoursePlayBtnTextColor', defaultColor: '#FBFCFD'},
  {cssColor: '--myCourse-play-again-btn-text-color', configColor: 'myCoursePlayAgainBtnTextColor', defaultColor: '#FBFCFD'},
  {cssColor: '--myCourse-completed-text-color', configColor: 'myCourseCompletedTextColor', defaultColor: '#FBFCFD'},
  {cssColor: '--faq-background-color', configColor: 'faqBackgroundColor', defaultColor: '#FFFFFF'},
  {cssColor: '--faq-button-color', configColor: 'faqButtonColor', defaultColor: '#F39200'},
  {cssColor: '--faq-question-color', configColor: 'faqQuestionColor', defaultColor: '#023770'},
  {cssColor: '--faq-answer-color', configColor: 'faqAnswerColor', defaultColor: '#6787A9'},
  {cssColor: '--certificate-button-background-color', configColor: 'certificateButtonBackgroundColor', defaultColor: '#FFFFFF'},
  {cssColor: '--certificate-button-color', configColor: 'certificateButtonColor', defaultColor: '#023770'},
  {cssColor: '--certificate-download-icon-color', configColor: 'certificateDownloadIconColor', defaultColor: '#F39200'},
  {cssColor: '--no-course-text-color', configColor: 'noCourseTextColor', defaultColor: '#6787A9'},
  {cssColor: '--no-course-btn-bg-color', configColor: 'noCourseBtnBgColor', defaultColor: '#023770'},
  {cssColor: '--no-course-btn-text-color', configColor: 'noCourseBtnTextColor', defaultColor: '#FBFCFD'},
  {cssColor: '--progress-empty-color', configColor: 'progressEmptyColor', defaultColor: '#E5EBF0'},
  {cssColor: '--progress-fill-color', configColor: 'progressFillColor', defaultColor: '#6787A9'},
  {cssColor: '--profile-primary-text-color', configColor: 'profilePrimaryTextColor', defaultColor: '#023770'},
  {cssColor: '--profile-form-bg-color', configColor: 'profileFormBgColor', defaultColor: '#FFFFFF'},
  {cssColor: '--profile-form-btn-bg-color', configColor: 'profileFormBtnBgColor', defaultColor: '#023770'},
  {cssColor: '--profile-form-btn-text-color', configColor: 'profileFormBtnTextColor', defaultColor: '#FBFCFD'},
  {cssColor: '--profile-form-input-text-color', configColor: 'profileFormInputTextColor', defaultColor: '#6787A9'},
  {cssColor: '--profile-form-input-bg-color', configColor: 'profileFormInputBgColor', defaultColor: '#FBFCFD'},
  {cssColor: '--profile-form-input-border-color', configColor: 'profileFormInputBorderColor', defaultColor: '#E5EBF0'},
  {cssColor: '--fallback-text-color', configColor: 'fallbackTextColor', defaultColor: '#6787A9'},
  {cssColor: '--career-title-primary-color', configColor: 'careerPrimaryTitleColor', defaultColor: '#023770'},
  {cssColor: '--career-title-secondary-color', configColor: 'careerSecondaryTitleColor', defaultColor: '#023770'},
  {cssColor: '--career-title-tertiary-color', configColor: 'careerTertiaryTitleColor', defaultColor: '#FFFFFF'},
  {cssColor: '--career-btn-primary-bg-color', configColor: 'careerBtnPrimaryBgColor', defaultColor: '#023770'},
  {cssColor: '--career-btn-primary-text-color', configColor: 'careerBtnPrimaryTextColor', defaultColor: '#FBFCFD'},
  {cssColor: '--career-posProgress-bg-color', configColor: 'careerPosProgressBgColor', defaultColor: '#8E9AA0'},
  {cssColor: '--career-actualpos-color', configColor: 'careerActualPosColor', defaultColor: '#D1AE22'},
  {cssColor: '--career-level1pos-color', configColor: 'careerLevel1PosColor', defaultColor: '#00AED6'},
  {cssColor: '--career-level2pos-color', configColor: 'careerLevel2PosColor', defaultColor: '#B05C83'},
  {cssColor: '--career-posDetails-text-color', configColor: 'careerPosDetailsTextColor', defaultColor: '#6787A9'},
  {cssColor: '--career-selector-bg-color', configColor: 'careerSelectorBgColor', defaultColor: '#FBFCFD'},
  {cssColor: '--career-selector-text-color', configColor: 'careerSelectorTextColor', defaultColor: '#6787A9'},
  {cssColor: '--career-graph-actual-bg-color', configColor: 'careerGraphActualBgColor', defaultColor: '#D1AE22'},
  {cssColor: '--career-graph-required-bg-color', configColor: 'careerGraphRequiredBgColor', defaultColor: '#01CBF9'},
  {cssColor: '--devplan-primary-text-color', configColor: 'devPlanPrimaryTextColor', defaultColor: '#6787A9'},
  {cssColor: '--devplan-table-border-color', configColor: 'devPlanTableBorderColor', defaultColor: '#023770'},
  {cssColor: '--devplan-btn-primary-text-color', configColor: 'devPlanBtnPrimaryTextColor', defaultColor: '#023770'},
  {cssColor: '--errorpage-icon-color', configColor:'errorpageIconColor', defaultColor: '#6787A9'},
  {cssColor: '--errorpage-title-color', configColor:'errorpageTitleColor', defaultColor: '#023770'},
  {cssColor: '--errorpage-text-color', configColor:'errorpageTextColor', defaultColor: '#6787A9'},
  {cssColor: '--errorpage-btn-bg-color', configColor:'errorpageBtnBgColor', defaultColor: '#023770'},
  {cssColor: '--errorpage-btn-text-color', configColor:'errorpageBtnTextColor', defaultColor: '#FBFCFD'},
]

export const applyColors = (root, configData) => {
  for (const obj of colorsArray) {
    if(configData?.colors?.[obj.configColor]){
      root?.style.setProperty(obj.cssColor, configData.colors[obj.configColor])
    } else {
      root?.style.setProperty(obj.cssColor, obj.defaultColor)
    }
  }
}
